import React, { useState, useEffect } from 'react';

const ItemPagesOne = ({items,popup,color,setcords}) => {
    const hendles = (e,id) =>{
        //console.log(e.target.offsetTop);
        setcords(e.target.offsetTop)
        popup(id)
        //console.log(document.body.offsetHeight);
    }
    return (
        <div className={'sy_come ' + color} >
        {
            items && items.length !== 0 && items.map((val,index)=>{
                //console.log(val);
                return (
                    <div key={index} className="sy_comeitem">
                        <div className="sy_comeitemimg" onClick={(e)=> hendles(e,val.id)}>
                            <img src={val.acf.logo} loading="lazy" alt="" className="sy_comeitemimg-img"/>
                        </div>
                        <div className="sy_comeitemtitl" onClick={()=> popup(val.id)}>{val.title.rendered}</div>
                        <div className="sy_comeitemtext">{val.acf.short}</div>
                    </div>
                )
            })
            
        } 
        </div>
    )
}
export default ItemPagesOne