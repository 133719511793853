import React, { useState,useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Category from "./components/category";
import Head from "./components/head";
import NotFound from './components/NotFound';
import Pages from "./components/pages";
import Project from "./components/Project";

function App() {
  const [ids, setIds] = useState(0);
  const myRef = useRef(null)
  //const usrl = 'http://localhost:3000/wp-content/themes/suvenir/'
  const usrl = window.location.origin + '/wp-content/themes/suvenir/'

  //console.log(window.location.origin + 'wp-content/themes/suvenir/')
  
  return (
    <div className="sy_container">
      <Router>
        <Head url={usrl} ids={ids} setIds={setIds} myRef={myRef} />
        <Category url={usrl} ids={ids} setIds={setIds} myRef={myRef} />
        <div ref={myRef} class="sy_more">
            <img src={usrl + 'images/more.png'} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 97vw, 98vw" srcset={usrl + 'images/more.png'} alt=""/>
        </div>

          <Switch>
            <Route path="/page/:id" render={(props) => (
    <Pages {...props} url={usrl} isAuthed={true} />
  )} />
            
            <Route path="/">
              
            </Route>


            <Route component={NotFound} />
            
          </Switch>
          <Project url={usrl} />
        
      </Router>
    </div>
  );
}

export default App;
