import React, { useState, useEffect } from 'react';
import Api from "../api/Api";
import ItemPagesOne from './itempage_one';
import ItemPagesTwo from './itempage_two';
import PopupOne from './Popup_one';
import PopupTwo from './Popup_two';





const Pages = (props) => {
    const [items, setItems] = useState(null);
    const [cat, setCat] = useState(null);
    const [popups, setPopup] = useState(null);
    const [cords, setCords] = useState(null);
    const [spiner, setSpiner] = useState(false);
    const id = props.match.params.id
    //console.log(cat); 
    
    /**/
    const {url} = props
   
    useEffect(() => {   
        setSpiner(false)
        Api.Pages(id).then(res =>{
            setItems(res.data)
            return new Promise((res,rej)=>{
                setTimeout(() => {
                    res(true)
                    
                }, 500);
            })
        }).then(val => {
            console.log('val', val);
            val && setSpiner(true)
        })
        Api.getCat(id).then(res =>{
            setCat(res.data)
        })
    },[id]);

    //yellow  #e2ad13

    //blue #c5aee6

    // red #fa8fb6

    console.log('spin',spiner);

   

    return (
        <>
        
        <div className="contextboxes">
         {
            !spiner && <div className="spiner"><img src={url + 'images/spiner.gif'} /></div> 
         }   
        {
            cat && spiner &&
            <div className="sy_cattitle">
            <div className={'sy_titleplash ' + cat.acf.rub_color}> 
            <div className="sy_titleplashtext">{cat.name}</div>
            </div>
                <h1 className={ 'sy_catsub_title ' + ((id == 2 || id == 3) && 'sy_catsub_title2')}>{cat.acf.text}</h1>
            </div>
            
        }
        {  
            (id == 2 || id == 3) 
            ? spiner && <ItemPagesTwo color={cat && cat.acf.rub_color} items={items} popup={setPopup} setcords={setCords} />
            : spiner && <ItemPagesOne color={cat && cat.acf.rub_color} items={items} popup={setPopup} setcords={setCords}/> 
            
            
        }
        {
            (id == 2 || id == 3) 
            ? <PopupTwo color={cat && cat.acf.rub_color} popups={popups} setpup={setPopup} cords={cords} id={id} />   
            : <PopupOne color={cat && cat.acf.rub_color} popups={popups} setpup={setPopup} cords={cords} id={id} /> 
        }
        {
            items && items.length === 0 && <div className="nopage">Ничего не найдео</div>
        }
        {
            (id == 2 || id == 3) &&
            <>
            <div class={'sy_contoperation ' + (cat && cat.acf.rub_color)}>
                <div class="sy_contoperationbox">
                    <div class="sy_contoperationboxtext">контакты оператора:</div>
                </div>
            </div>
            <div class={'sy_contacte ' + (cat && cat.acf.rub_color)}>
                <div class="sy_contacteitem">
                    <div class="sy_contactetext">юридическое наименование</div>
                    <div class="sy_contactetext2">{cat && cat.acf.urist}</div>
                </div>
                <div class="sy_contacteitem">
                    <div class="sy_contactetext">телефон:</div>
                    <div class="sy_contactetext2">{cat && cat.acf.phone}</div>
                </div>
                <div class="sy_contacteitem">
                    <div class="sy_contactetext">сайт:</div>
                    <a href={cat && cat.acf.site} class="sy_contactetext2">{cat && cat.acf.site}</a> 
                </div>
            </div>
            </>
        }
        
        
               
        </div>
        </>
            
    )
}
export default Pages