import React, { useState, useEffect } from 'react';
import Api from "../api/Api";
import { MenuColor } from '../utils';
import {Link} from "react-router-dom";

const Head = ({url,setIds,ids,myRef}) =>{
    const [cat, setCat] = useState(null);
    const [checked, setChecked] = useState(false);
    const toggleChecked = () => setChecked(value => !value);
    
    useEffect(() => {   
        Api.Category().then(res =>{
            setCat(res.data)
        })
    },[]);

    console.log(url);

    const executeScroll = (id) => {
        
        setIds(id)
        myRef && myRef.current.scrollIntoView({
            behavior: "smooth",
          })  
    }  

    
    
    return (
        <div className="sy_head headfix">
            <Link to="/" className="sylogo" onClick={()=> setIds(0)}>
                <img src={url +'images/Лого.png'} loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 991px) 34vw, 25vw" srcset={url +'images/Лого.png'} alt=""/>
            </Link>
        <div className="sy_menuhead">
            {
                cat && cat.map((val,index)=>{
                    //console.log(val);
                    let img = ''
                    //const {rub_img:{sizes:{large}}} = val.acf
                    //const q = val.acf.rub_img
                    
                    if(val.acf.rub_color){
                        return (
                            <div key={index} className="sy_menuheadbox">
                                <div className="sy__menuhead-box-img">
                                    
                                    <img src={url + 'images/' + MenuColor(val.acf.rub_color)} loading="lazy" sizes="(max-width: 991px) 12px, 1vw" srcset={url + 'images/' + MenuColor(val.acf.rub_color)} alt="" className="sy_headboximg"/>
                                    <Link to={'/page/' + val.id} onClick={()=> executeScroll(val.id)} className={'sy_headboxtext ' + (ids == val.id && 'head-active')}  >{val.name}</Link>
                                </div>
                                
                            </div>
                        )
                    }
                })

            }
            
            
        </div>
        <div className={'mobileovelow ' +  (checked && 'mobileovelow-open')} onClick={toggleChecked}> </div>
        <div className="mobile">
            <div className={'mobile_torrge ' +  (checked && 'mobile_torrge-open')} onClick={toggleChecked}></div>
            <div className={'mobile_menu ' +  (checked && 'mobile_menu-open')}>
                {
                    cat && cat.map((val,index)=>{
                        //console.log(val);
                        let img = ''
                        //const {rub_img:{sizes:{large}}} = val.acf
                        //const q = val.acf.rub_img
                        
                        if(val.acf.rub_color){
                            return (
                                <div key={index} className="sy_menuheadbox">
                                    <div className="sy__menuhead-box-img">
                                        
                                        <img src={url + 'images/' + MenuColor(val.acf.rub_color)} loading="lazy" sizes="(max-width: 991px) 12px, 1vw" srcset={url + 'images/' + MenuColor(val.acf.rub_color)} alt="" className="sy_headboximg"/>
                                        <Link to={'/page/' + val.id} onClick={()=> executeScroll(val.id)} className={'sy_headboxtext ' + (ids == val.id && 'head-active')}  >{val.name}</Link>
                                    </div>
                                    
                                </div>
                            )
                        }
                    })

                }
            </div>
        </div>
        </div>
    )
}
export default Head