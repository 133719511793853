import React, { useState, useEffect,useRef } from 'react';
import Api from "../api/Api";


const PopupOne = ({popups,setpup,color,cords}) => {
  const [popcont, setPopcont] = useState(null);
  const refContainer = useRef();

  const divStyle = {
    top: cords + 'px',

  };
    useEffect(()=>{
      popups && Api.getPage(popups).then(res =>{
          setPopcont(res.data)
        }) 
        position(cords)

    },[popups])

    const position = posit =>{
      
      let elem = document.getElementById('popup');
      
    }
    


    return (
        <>
        {popcont && <div class={'sy_oveflow ' + color}  onClick={() => {
          setPopcont(null) 
          setpup(null)
          }} ></div>} 
        {popcont &&
        <div id="popup" style={divStyle} class={'sy_popup-cat sy_popup-catactive ' + color}>
          <div className="sy_popup-close" onClick={() => {
          setPopcont(null) 
          setpup(null)
          }}></div>
          <img src={popcont.acf.logo} loading="lazy" alt="" class="sy_popup-catimg"/>
        <div class="sy_popup-cattitle">{popcont.title.rendered}</div>
        <div class="sy_popup-cattext">{popcont.acf.text}</div>
        <div class="sy_popup-catimboxe">
          <div class="sy_popup-catimgbox"><img src={popcont.acf.imga1} loading="lazy" alt="" class="sy_popup-catimgboximg"/></div>
          <div class="sy_popup-catimgbox"><img src={popcont.acf.imga2} loading="lazy" alt="" class="sy_popup-catimgboximg"/></div>
        </div>
        <div class="sy_contoperation">
          <div class="sy_contoperationbox">
            <div class="sy_contoperationboxtext">КОНТАКТЫ ПРОИЗВОДИТЕЛЯ:</div>
          </div>
        </div>
        <div class="sy_contacte">
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">юридическое наименование</div>
            <div class="sy_contactetext2 sy_popupcont2">{popcont.acf.urist}</div>
          </div>
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">телефон:</div>
            <a href={'tel:' + popcont.acf.phone} class="sy_contactetext2 sy_popupcont2">{popcont.acf.phone}</a>
          </div>
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">сайт:</div>
            <a href={popcont.acf.urlsite ? popcont.acf.urlsite : popcont.acf.site} target="_blank" class="sy_contactetext2 sy_popupcont2">{popcont.acf.site}</a>
          </div>
        </div>
      </div>
      }
      </>
    )
}
export default PopupOne