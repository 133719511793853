import React, { useState, useEffect } from 'react';
import Api from "../api/Api";


const PopupTwo = ({popups,setpup,color,cords,id}) => {
  const [popcont, setPopcont] = useState(null);
    useEffect(()=>{
      popups && Api.getPage(popups).then(res =>{
          setPopcont(res.data)
        }) 
    },[popups])

    const divStyle = {
      top: cords + 'px',
  
    };
    const position = posit =>{
      
      let elem = document.getElementById('popup');
      
    }

    

    return (
        <>
        {popcont && <div class={'sy_oveflow ' + color} onClick={() => {
          setPopcont(null) 
          setpup(null)
          }} ></div>} 
        {popcont &&
        <div id="popup" class={'sy_popup-cat sy_popup-catactive ' + color} style={divStyle}>
          <div className="sy_popup-close" onClick={() => { 
          setPopcont(null) 
          setpup(null)
          }}></div>
          <div class="sy_popup-catmagz">
          <div class="sy_popup-catmagzleft"><img src={popcont.acf.imga1} loading="lazy" alt="" class="sy_popup-catmagzleftimg"/></div>
          <div class="sy_popup-catmagzright">
            <div class="sy_popup-catmagzrighttitle">{popcont.title.rendered}</div>
            <div class="sy_popup-catmagzrighttext">{popcont.acf.text}</div>
            <div class="sy_proprice">
              <div class="sy_propricetext sy_popupprice">{popcont.acf.price} p</div>
            </div>
            {
              (id == 3) &&
              <div class="sy_popup-catmagzrightifo">
            *Стоимость подарочного набора указана ориентировочно. <br/>
              Точную стоимость Вы можете узнать у оператора
            </div>
            }
            {
              (id == 2) &&
              <div class="sy_popup-catmagzrightifo">
              *Стоимость упаковки указана ориентировочно. <br/>
              Точную стоимость Вы можете узнать у Оператора
              </div>
            }
            
          </div>
        </div>
        <div class="sy_contoperation">
          <div class="sy_contoperationbox">
            <div class="sy_contoperationboxtext">контакты оператора:</div>
          </div>
        </div>
        <div class="sy_contacte">
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">юридическое наименование</div>
            <div class="sy_contactetext2 sy_popupcont2">{popcont.acf.urist}</div>
          </div>
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">телефон:</div>
            <div class="sy_contactetext2 sy_popupcont2">{popcont.acf.phone}</div>
          </div>
          <div class="sy_contacteitem">
            <div class="sy_contactetext sy_popupcont1">сайт:</div>
            <a href={popcont.acf.urlsite} target="_blank" class="sy_contactetext2 sy_popupcont2">{popcont.acf.site}</a>
          </div>
        </div>
      </div>
      }
      </>
    )
}
export default PopupTwo