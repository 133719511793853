import React, { useState, useEffect } from 'react';
import Api from "../api/Api";
import {Link} from "react-router-dom";


const Category = ({url,setIds,ids,myRef}) =>{
    const [cat, setCat] = useState(null);
    
    useEffect(() => {   
        Api.Category().then(res =>{
            setCat(res.data)
        })
    },[]);


    const executeScroll = (id) => {
        
        setIds(id)
        console.log(myRef);
        myRef && myRef.current.scrollIntoView({
            behavior: "smooth",
          })  
    }
   
    


    return (
        <>
        <div className="sy_category">
            <h2 className="sy_title sy_mg1">Все крымские сувениры можно найти здесь</h2>
            <div className="sy_catbox">
            {
                cat && cat.map((val,index)=>{
                    if(val.acf.rub_img){
                        return (
                            <Link key={index} href="#" onClick={()=> executeScroll(val.id)} className="sy_catboxitem w-inline-block" to={'/page/' + val.id}>
                                <img src={val.acf.rub_img} loading="lazy" sizes="(max-width: 479px) 37vw, (max-width: 767px) 38vw, (max-width: 991px) 22vw, 23vw" srcset={val.acf.rub_img} alt="" className={'sy_catboximg ' + (ids == val.id && 'cat-active')} />
                            </Link>
                        )
                    } 
                })

            }
                
            </div>
        </div>
        </>    
    
    )
}
export default Category