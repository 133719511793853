import Axios from "axios";



const Api = () => {
    const api = Axios.create({
        baseURL: window.location.origin + '/wp-json/wp/v2/' 
        })
    return {
        Category(){
           return api.get('/categories?exclude=1')
        },
        getCat(id){
            return api.get('/categories/' + id)
        },
        Pages(id){
            return api.get('/posts/?per_page=50&categories=' + id)
        },
        getPage(id){
            return api.get('/posts/' + id)
        },
        getProject(){
            return api.get('/posts/?categories=10') 
        }
    }    

}

export default Api()