import React, { useState, useEffect } from 'react';

const ItemPagesTwo = ({items,popup,color,setcords}) => 
{
    const hendles = (e,id) =>{
        //console.log(e.target.offsetTop);
        setcords(e.target.offsetTop)
        popup(id)
        //console.log(document.body.offsetHeight);
    }
    return (
    <div className={'sy_openproject ' + color}>
    {
        items && items.length !== 0 && items.map((val,index)=>{
            //console.log(val);
             return (
                <div key={index} class="sy_openproject-item">
                    <div className="sy_openprojectimg-box">
                        <img src={val.acf.imga1} loading="lazy" alt="" onClick={(e)=> hendles(e,val.id)} class="sy_openprojectimg"/>
                    </div>
                    <div class="sy_openprojecttitl sy_upactitle" onClick={()=> popup(val.id)}>{val.title.rendered}</div>
                    <div class="sy_openprojecttext sy_upacttext">{val.acf.short}</div>
                    <div class="sy_proprice">
                    <div class="sy_propricetext">{val.acf.price} p</div>
                    </div>
                </div>
             )
         })
         
     } 
     </div>
    )
}
export default ItemPagesTwo