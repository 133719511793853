import React, { useState, useEffect } from 'react';
import Api from "../api/Api";

const Project = ({url}) =>{
    const [prodj, setProdj] = useState(null);
    const [checked, setChecked] = useState(false);
    const toggleChecked = () => setChecked(value => !value);
    useEffect(() => {   
        Api.getProject().then(res =>{
            setProdj(res.data)
        })
    },[]);


    //console.log(prodj);  

    return (
        <div class="sy_progect">
              <h2 onClick={toggleChecked} class="sy_title sy_mg1 prodjext">ПРОЕКТЫ ПРИ ПОДДЕРЖКЕ ЦЕНТРА <br/> КЛАСТЕРНОГО РАЗВИТИЯ РЕСПУБЛИКИ КРЫМ</h2>
              
              {
                  checked &&
                    <div class="sy_openproject">
                    {
                        prodj && prodj.map((val,index)=>{
                            
                            return (
                                <div key={index} class="sy_openproject-item">
                                    <a href={val.acf.url && val.acf.url} target="_blank">
                                        <img src={val.acf.img} loading="lazy" alt="" class="sy_openprojectimg"/>
                                    </a>
                                    <a href={val.acf.url && val.acf.url} target="_blank" class="sy_openprojecttitl">{val.title.rendered}</a>
                                    <div class="sy_openprojecttext">{val.acf.text}</div>
                                </div>
                            )
                        })
                    }
                    </div>
                  
                }
                
                    <div class="sy_projectbox">
                        <div class="sy_projectboxitem">
                        <a href="https://minek.rk.gov.ru/ru/index" class="w-inline-block">
                            <img src={url + 'images/p1.jpg'} loading="lazy" alt="" class="sy_projectboxitem-img"/>
                        </a>
                        </div>
                        <div class="sy_projectboxitem">
                        <a href="https://ckr.frbk.ru/" class="w-inline-block">
                            <img src={url + 'images/горизонтальная-версия-1.png'} loading="lazy" sizes="(max-width: 479px) 37vw, (max-width: 767px) 38vw, (max-width: 991px) 19vw, 20vw" srcset={url + 'images/горизонтальная-версия-1.png'} alt="" class="sy_projectboxitem-img"/>
                        </a>
                        </div>
                        <div class="sy_projectboxitem">
                        <a href="https://frbk.ru/" class="w-inline-block">
                            <img src={url + 'images/gorizontalniy-logotip_fppk.png'} loading="lazy" alt="" class="sy_projectboxitem-img"/>
                        </a>
                        </div>
                        <div class="sy_projectboxitem">
                        <a href="https://мойбизнес.рф/" class="w-inline-block">
                            <img src={url + 'images/лого-мой-бизнес-2019-РК.png'} loading="lazy" sizes="(max-width: 479px) 37vw, (max-width: 767px) 38vw, (max-width: 991px) 19vw, 20vw" srcset={url + 'images/лого-мой-бизнес-2019-РК.png'} alt="" class="sy_projectboxitem-img"/>
                        </a>
                        </div>
                    </div>
                    <div class="sy_foottext">Если хотите стать участником каталога отправляйте заявку на электнонную почту: <a class="linkee" href="mailto:ckr@frbk.ru">ckr@frbk.ru</a> </div>
                    
                 
              
             </div> 
    )
}
export default Project